.bootstrap-style-container {
  @import 'node_modules/bootstrap/scss/bootstrap.scss';
}

@mixin flex($direction) {
  display: flex;
  flex-direction: $direction;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-text-size-adjust: 100%; //fix iOS first loading with bigger fonts
  overflow: hidden;
}
.app {
  @include flex(column);
}

.app-header {
  flex-grow: 0;
}

.app-content {
  @include flex(column);
  flex-grow: 1;
  overflow: auto;
}

.app-content::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: inherit;
}

.app-footer {
  flex-grow: 0;
}

.app-footer-content {
  @include flex(row);
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  width: 100vw;
}
.app-navigator {
  flex-grow: 0;
  width: 100vw;
}

.app-sub-content {
  @include flex(column);
  flex-grow: 1;
  overflow: auto;
}

.app-sub-content::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: inherit;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.ReactVirtualized__Grid__innerScrollContainer {
  padding-bottom: 10px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
