.timeline-container:before {
  content: 'Inicio';
  padding: 3px 6px;
  font-size: 10px;
  text-transform: uppercase;
  background: #666;
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 2;
  left: -2px;
}

.timeline-container-after:after {
  content: 'Fim';
  padding: 3px 6px;
  font-size: 10px;
  text-transform: uppercase;
  background: #666;
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 2;
  top: -20px;
  left: 3px;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 3px;
  background-color: #eeeeee;
  margin-left: -4.5px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,eeeeee+100&0.91+0,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(75, 75, 75, 0.91) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(75, 75, 75, 0.91) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(75, 75, 75, 0.91) 0%,
    rgba(238, 238, 238, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8000000', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  float: left;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  position: relative;
  border-radius: 3px;
  padding-top: 15px;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 15px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #dcdcdc;
  border-right: 0 solid #dcdcdc;
  border-bottom: 15px solid transparent;
  content: ' ';
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 16px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #ffffff;
  border-right: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content: ' ';
}

.timeline > li > .alteracao-status-timeline-item:after {
  border-right: 0 solid #f0f0f0;
}

.timeline > li > .alteracao-status-manual-timeline-item:after {
  border-right: 0 solid #f0f0f0;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 42px;
  height: 42px;
  line-height: 27px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 8px;
  margin-left: -24px;
  background-color: #999999;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li > .timeline-badge > img {
  width: 42px;
  height: 42px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.comentario {
  background-color: #999999 !important;
}

.timeline-badge.atividade {
  background-color: #468847 !important;
}

.timeline-badge.comentario-restrito {
  background-color: #b94a48 !important;
}

.timeline-badge.email-recebido {
  background-color: #3a87ad !important;
}

.timeline-badge.email-enviado {
  background-color: #f89406 !important;
}

.timeline-badge.alteracao-status-manual {
  background-color: #f89406 !important;
}

.comentario-titulo {
  color: #000 !important;
}

.atividade-titulo {
  color: #468847 !important;
}

.alteracao-status-titulo {
  color: #3a87ad !important;
}

.alteracao-status-manual-titulo {
  color: #f89406 !important;
}

.alteracao-status-timeline-item {
  background-color: #f0f0f0 !important;
}

.alteracao-status-manual-timeline-item {
  background-color: #f0f0f0 !important;
}

.comentario-restrito-titulo {
  color: #b94a48 !important;
}

.email-recebido-titulo {
  color: #3a87ad !important;
}

.email-enviado-titulo {
  color: #f89406 !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-heading h5 {
  font-weight: 800;
  padding: 0 15px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.timeline-body {
  padding: 5px 15px 15px 0;
}

.timeline-body > p,
.timeline-body > ul {
  padding: 10px 15px;
  margin-bottom: 0;
  font-size: 12px;
}

.timeline-body .tooltip-cursor {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  color: #666;
  display: inline-block;
  margin-left: -5px;
}

.timeline-body .badge {
  color: #fff;
  font-size: 12px;
  margin: 5px 0 4px;
}

.timeline-footer {
  background: #f9f9f9;
  border-top: 1px solid #dcdcdc;
  padding: 5px 15px;
  line-height: 14px;
  border-radius: 0 0 3px 3px;
}

.timeline-footer p {
  margin-bottom: 0;
}
.timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}

.timeline-footer strong {
  font-size: 10px;
  text-transform: uppercase;
}

.timeline-footer > strong {
  margin-top: 2px;
}

.Component-button-134 {
  right: 15px !important;
  bottom: 75px !important;
}

@media (max-width: 767px) {
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
  }

  ul.timeline > li > .timeline-badge {
    top: 8px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

@media (max-width: 320px) {
  .timeline-footer strong {
    font-size: 9px;
  }
}

.timeline {
  padding-left: 20px;
}

.timeline-panel a {
  text-decoration: none;
}

.timeline-body > p {
  padding: 5px 0 0 15px;
}

.badge-disabled {
  color: grey !important;
  background: #ffffff;
  text-decoration: none !important;
  font-weight: normal !important;
}

.badge-disabled:hover {
  color: grey !important;
  background: #ffffff;
  text-decoration: none !important;
  font-weight: normal !important;
}

.exibir {
  margin-left: 50px;
}

.margin-left {
  margin-left: 5px !important;
}

.tooltip-cursor {
  cursor: pointer;
}

.tooltip-badge .fa {
  margin: 0 6px;
}

.tooltip-status {
  display: inline-block;
  background: #666666;
  padding: 3px 6px !important;
  text-transform: capitalize;
  border-radius: 12px;
  margin-left: 15px;
  margin-top: 4px !important;
  color: #fff;
  font-weight: bold;
}

.timeline-responsavel {
  color: grey !important;
}

.timeline-atividade-atrasada {
  color: #8a0808 !important;
}

.timeline-body > .row-fluid {
  padding: 5px 0px 0px 15px !important;
  min-height: 15px !important;
  line-height: 15px !important;
}

.line {
  min-height: 15px !important;
  line-height: 15px !important;
}

.margin-l-0 {
  margin-left: 0px !important;
}

.status-arrow {
  margin: 0px 3px;
}
